.group-home-content,
.goal-view-content,
.plan-view-content,
.user-content,
.home-view-content {
  height: 97%;
  padding: 1%;
  overflow-y: scroll;
  font-family: 'Times New Roman', Times, serif;

  .group-header {
    display: flex;

    .user-name {
      width: 100%;
      font-size: 20px;
    }

    .header {
      padding-left: 15px;
      color: #202020;
      font-size: 30px;
    }

    button,
    select {
      position: fixed;
      right: 15px;
      outline: none;
      z-index: 100;

      &.left {
        right: 90px;

        &.goal {
          margin-right: 12px;
        }
      }

      &.disabled {
        background-color: #aeaeae;
        cursor: not-allowed;
      }
    }

    .plan-botton-back {
      right: 329px;
    }

    .plan-botton-print {
      right: 275px;
    }

    select {
      height: 35px;
      border-radius: 5px;
      border-color: #3ab06a;
      background-color: #3ab06a;
      color: #ffffff;
      font-family: 'Times New Roman', Times, serif;

      &.goals-select {
        width: 175px;
      }
      &.stos-select {
        width: 65px;
      }
    }
  }

  .client-list-container,
  .plan-list-container {
    float: left;
    height: 94%;
    margin-top: 1%;
  }

  .client-list-container {
    width: 20%;
  }

  .assign-list-container {
    width: 50%;

    .assign-header {
      margin-top: 20px;
      font-size: 20px;
      font-weight: bold;
    }
    .assign-home-list {
      width: 100%;
      padding: 20px;

      .home-list {
        position: relative;
        padding: 10px;
        width: 100%;
        margin: 8px;
        background-color: white;
        border-radius: 5px;

        .action-button {
          position: absolute;
          right: 10px;
          font-size: 20px;

          &:hover {
            cursor: pointer;
          }
        }

        .action-assigned {
          color: #43c533;
        }
        .action-unassigned {
          color: #ef3737;
        }
      }
    }
  }

  .plan-list-container {
    width: 79%;
    margin-left: 1%;
    background-color: #ffffff;
    border-radius: 3px;
    overflow-y: scroll;
  }
}

.home-view-content {
  overflow: hidden;
}
