.pages-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  .buttons {
    position: fixed;
    margin: 15px;
    .dispose {
      margin-right: 15px;
      background-color: #d6d6d6;
      color: white;
    }
  }

  .page {
    width: 816px;
    height: 1056px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    page-break-after: always;
    position: relative;
    font-family: 'Times New Roman', Times, serif;

    &:last-child {
      margin-bottom: 20px;
      page-break-after: avoid;
    }

    .header {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 20px;
    }

    .client-info-header {
      margin-bottom: 40px;
      .home-client-info {
        line-height: 18px;
      }
    }

    .continues-test-wraper {
      margin-bottom: 20px;
    }

    .content {
      font-size: 15px;
    }

    .rational-justification,
    .assessment-tool,
    .stimulus-preference-assessment,
    .teaching-strategies,
    .data-collection-system,
    .monitoring-plan,
    .staff-trainers {
      font-weight: bold;

      &.under-line {
        text-decoration: underline;
      }
    }

    .plan-content {
      margin-bottom: 20px;
      white-space: pre-wrap;
    }

    .goals-header {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .goals-content {
      margin-bottom: 20px;
      .goal-content-wraper {
        margin-top: 10px;
        &.margin-both-side {
          margin-bottom: 10px;
        }
        .goal-content {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
    .stos-content {
      .sto-content {
        font-weight: bold;
      }
    }

    .sign-footer {
      width: 100%;
      margin-left: 25px;
      td {
        &.consultan-sign {
          height: 90px;
        }

        &.consultan-name {
          padding-left: 55px;
          height: 60px;
        }

        &.caregiver {
          padding-bottom: 100px;
        }

        &.sign {
          width: 60px;
        }

        &.sign-date {
          width: 5px;
        }

        &.here {
          width: 100px;
          border-bottom: 1px solid black;
        }

        &.white-space {
          width: 100px;
        }
      }
    }

    .summary {
      font-size: 14px;
      text-align: center;
    }

    .goal-table-report,
    .goal-info-report {
      font-size: 13px;
      tr {
        td,
        th {
          padding: 0;
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      &.one-page {
        font-size: 15px !important;
      }
    }

    .goal-table-report {
      text-align: center;
    }

    .sign-report-footer {
      margin: 0;
      margin-top: 3%;
      font-size: 14px;
      border-collapse: inherit;
      tr {
        border: none;
        td {
          width: 15%;

          &.sign-field {
            border-top: 1px solid black;
          }

          &.no-border {
            border: none !important;
          }

          &.sign-report-container {
            height: 150px;
          }
        }
      }
    }

    &.landscape {
      width: 1056px !important;
      height: 816px !important;

      .container {
        .sheet-container-header {
          margin-bottom: 5px;
        }

        table {
          margin-bottom: 20px;
          td {
            text-align: left;
            padding: 0;
            padding-left: 5px;
            font-size: 14px;
          }
          th {
            text-align: center;
            width: 15px;
            padding: 0;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;

    .buttons {
      display: none;
    }

    .page {
      padding-left: 0 !important;
      padding-right: 0 !important;
      .goal-table-report,
      .goal-info-report {
        font-size: 17px !important;
      }
      .content {
        font-size: 17px !important;
      }
    }

    &.landscape {
      transform: rotate(-90deg) !important;
      height: 1156px !important;
      overflow: visible;
      .page.landscape {
        transform: rotate(-90deg);
        .container {
          font-size: 22px !important;
          margin-left: -25px;
          .table {
            th {
              font-size: 16px !important;
            }
            td {
              font-size: 18px !important;
            }
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
}
