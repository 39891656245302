.form-container {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;

  .new-edit-form {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 50%;

    .form-header {
      position: fixed;
      top: 22%;
      font-size: 30px;
    }

    .fields,
    .fields-textarea {
      width: 100%;
      height: 50px;
      margin: 20px;

      .form-label {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        width: 50%;
        margin: auto;
        padding: 5px;
      }

      input {
        width: 50%;
        height: 30px;
        border-radius: 5px;
        border: 0;
        padding-left: 5px;

        &:focus {
          border: 1px solid #3ab06a;
          box-shadow: 0 0 3px #3ab06a;
          outline-offset: 0px;
          outline: none;
        }

        &.goal,
        &.sto {
          background-color: #f7f7f7;
        }
      }

      .error-form {
        width: 75%;
        text-align: right;
        font-size: 10px;
        color: #ef3737;
        margin-bottom: 10px;
      }
    }

    .fields-admin {
      width: 100%;
      height: 50px;
      margin: 20px;
      padding-left: 25%;
      padding-top: 10px;
      display: flex;

      .form-label {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        padding: 5px;
      }

      input {
        width: 18px;
        height: 35px;
        border-radius: 5px;
        border: 0;
        margin-left: 5px;
        background-color: lawngreen !important;
      }
    }

    .fields-textarea {
      height: 130px;

      textarea {
        max-width: 50%;
        min-width: 50%;
        min-height: 110px;
        max-height: 110px;
        border-radius: 5px;
        border: 0;

        &:focus {
          border: 1px solid #3ab06a;
          box-shadow: 0 0 3px #3ab06a;
          outline-offset: 0px;
          outline: none;
        }

        &.goal {
          background-color: #f7f7f7;
        }
      }
    }

    .form-buttons {
      width: 100%;
      height: 50px;
      padding: 20px;

      .home-save-form-button,
      .home-back-form-button {
        width: 20%;
        height: 40px;
        margin-right: 20px;
        border: 0;
        border-radius: 5px;

        &:hover {
          cursor: pointer;
        }

        &:focus {
          outline: none;
        }
      }

      .home-save-form-button {
        background-color: #3ab06a;
        color: #ecf9ea;
      }

      .home-back-form-button {
        background-color: #aeaeae;
        color: #ffffff;
      }

      &.plan-buttons {
        position: fixed;
        bottom: 90px;

        &.goal {
          bottom: 100px;
          left: 12%;
        }
      }
    }

    &.plan-form {
      overflow: auto;
    }
  }
}
