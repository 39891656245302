.clients-content,
.users-list-container,
.user-home-list,
.goals-content {
  height: 100%;
  overflow-y: auto;

  .client-header,
  .goal-header {
    font-size: 20px;
    margin: 15px;
  }

  .client,
  .user,
  .goal {
    padding: 5px 15px;
    position: relative;
    background-color: #ffffff;
    border-radius: 3px;
    margin-top: 5px;

    a {
      text-decoration: none;
      color: #202020;
    }

    .action-edit,
    .action-sto,
    .action-home,
    .action-remove {
      position: absolute;
      right: 25px;
      color: #3ab06a;
      padding-right: 3px;

      &:hover {
        cursor: pointer;
        font-size: 12px;
      }

      &.action-remove {
        right: 0;
        color: #ef3737;
      }
      &.action-sto {
        right: 50px;
        color: #f88f6c;
      }
      &.action-home {
        right: 55px;
        color: #8d6e63;
      }
    }

    &:hover {
      background-color: #d6d6d6;
    }

    &.selected {
      background-color: #d6d6d6;
    }
  }

  .user {
    height: 60px;
    width: 65%;
  }
}
