.add-button {
  font-family: 'Times New Roman', Times, serif;
  border: 0;
  border-radius: 5px;
  height: 35px;
  background-color: #3ab06a;
  color: #ecf9ea;

  &:hover {
    cursor: pointer;
  }

  &.no-sto-back-button {
    margin: 15px;

    &.left {
      position: absolute;
      right: 60px;
    }
  }
}
