.container {
  width: 100%;
  height: 100vh;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 80%;
  height: 60%;
  margin: 50px auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 300px;
  height: 100px;
  background-color: white;
  margin-top: 20px;
}

.sigButtonContainer {
  text-align: center;

  .add-button {
    font-family: 'Times New Roman', Times, serif;
    border: 0;
    border-radius: 5px;
    min-width: 100px;
    height: 35px;
    background-color: #3ab06a;
    color: #ecf9ea;
    margin-right: 20px;

    &:hover {
      cursor: pointer;
    }

    &.clear {
      background-color: #ef3737;
    }
    &.trim {
      background-color: #f88f6c;
    }
  }
}
