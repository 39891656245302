.group-plan-content {
  height: 100%;

  .group-header {
    padding: 5px 20px;
    text-align: center;
    .header {
      width: 100%;
      font-size: 20px;
    }
  }

  .not-info {
    height: 100%;
    width: 100%;
    display: table;

    .empty-text,
    .empty {
      width: 100%;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      font-size: 30px;
      svg {
        width: 40px;
        height: 45px;
      }
    }
  }
}

.plan-view-content {
  table {
    border: none;
    font-size: 12px;
    margin-top: 20px;
    font-family: 'Times New Roman', Times, serif;
    tr {
      width: 2px;
      height: 2px;
      th.remove-borders {
        border: none;
        text-align: right;
      }
      td {
        padding: 0;
        .data-select {
          font-family: 'Times New Roman', Times, serif;
          font-size: 11px;
          border: 0;
          padding-left: 10px;
          width: 100%;
          height: 109px;
          -webkit-appearance: none;
          appearance: none;

          &:focus {
            border-color: lawngreen;
          }
        }
      }

      td.today,
      th.today {
        background-color: #b4e6fd !important;
      }
    }
  }
}
