.setting-content {
  height: 97%;
  padding: 1%;
  overflow-y: scroll;
  font-family: 'Times New Roman', Times, serif;

  .header {
    padding-left: 15px;
    color: #202020;
    font-size: 30px;
  }

  li {
    &:hover {
      cursor: pointer;
      background-color: #d6d6d6;
    }
  }
}
