.cards-content {
  height: initial;
  width: 100%;
  display: table;

  .card {
    height: 250px;
    max-height: 250px;
    width: 14%;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin: 1%;
    position: relative;
    padding: 5px 15px;
    float: left;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);

    span {
      text-align: center;
      display: block;
      padding: 5px 0;
    }

    .name {
      font-size: 20px;
    }
    .contact {
      font-size: 18px;
    }
    .address {
      font-size: 12px;
    }
    .phone {
      font-size: 10px;
    }

    .action-container {
      width: 78%;
      height: 30px;
      margin: 0 7%;
      border-top: 1px solid #8c8c8c;
      position: absolute;
      bottom: 0;
      text-align: center;
      display: table;

      span {
        float: right;
        display: table-cell;
        vertical-align: middle;
        margin-left: 12px;
        margin-top: 2px;

        &:hover {
          cursor: pointer;
          background-color: #f7f7f7;
          border-radius: 20%;
        }

        &.action-see {
          color: #43c533;
        }

        &.action-remove {
          color: #ef3737;
        }

        &.action-edit {
          color: #1f85ff;
        }

        &.action-goal {
          color: #f88f6c;
        }

        &.action-print {
          color: #ea80fc;
        }

        &.action-report {
          color: #8d6e63;
        }
      }

      .dropdown {
        position: absolute;
        font-size: 12px;
        top: 100%;
        left: 5%;
        width: 80px;
        z-index: 2;
        border: 1px solid rgba(0, 0, 0, 0.04);
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);

        .report-dropdown-button {
          width: 100%;
          height: 30px;
          border: 0;
          padding: 0;
          margin: 0;
          outline: none;

          &:hover {
            background-color: rgba(0, 0, 0, 0.14);
          }
        }
      }
    }
    &.plan {
      width: 20%;
      margin: 12px 27px;
    }
  }
}
