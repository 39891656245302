.loading-container {
  height: 95%;
  width: 100%;
  text-align: center;
  display: table;

  .loading-content {
    display: table-cell;
    vertical-align: middle;

    svg {
      height: 60px;
      width: 60px;
      fill: #aeaeae;
      rotate: 90deg;
      animation: rotation 2s infinite linear;
    }
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
}
